import React, { useEffect } from "react";
import { Suspense, lazy } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import AppLayout from "@/components/Layout";
import { Spinner } from "@material-tailwind/react";
import { useGlobalData } from "@/components/Layout/GlobalContext";
import { getSubdomain } from "@/utils";

const FallBack: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner className="h-20 w-20 text-gray-900/50" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

const Home = lazy(() => import("@/pages/home/index"));

const PrivateRoute: React.FC<{
  children: React.ReactNode;
  authKey: string;
}> = ({ children, authKey }) => {
  // const navigate = useNavigate();
  // const { userInfo } = useGlobalData();
  // useEffect(() => {
  //   if (authKey && userInfo && !userInfo.auth?.includes(authKey)) {
  //     navigate("/403");
  //   }
  // }, [userInfo]);
  return children;
};

const LazyComponentWithAuth = (
  importFunc: () => Promise<{ default: React.ComponentType<any> }>,
  authKey: string = ""
) => {
  const Component = lazy(importFunc);

  return (
    <FallBack>
      <PrivateRoute authKey={authKey}>
        <Component />
      </PrivateRoute>
    </FallBack>
  );
};

const Routes = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: (
          <FallBack>
            <Home />
          </FallBack>
        ),
      },
      {
        path: "/home/:code",
        element: (
          <FallBack>
            <Home />
          </FallBack>
        ),
      },
      {
        path: "/topic/:id",
        element: LazyComponentWithAuth(() => import("@/pages/topic/index")),
      },
      {
        path: "/topik",
        element: LazyComponentWithAuth(() => import("@/pages/topik/index")),
      },
      {
        path: "/category/:id",
        element: LazyComponentWithAuth(() => import("@/pages/category/index")),
      },
      {
        path: "/category",
        element: LazyComponentWithAuth(() => import("@/pages/category/list")),
      },
      {
        path: "/word",
        element: LazyComponentWithAuth(() => import("@/pages/word/index")),
      },
      {
        path: "/word/:type",
        element: LazyComponentWithAuth(() => import("@/pages/word/index")),
      },
      {
        path: "/about",
        element: LazyComponentWithAuth(() => import("@/pages/about/index")),
      },
    ],
  },
  {
    path: "/chat/:appId",
    element: LazyComponentWithAuth(() => import("@/pages/chat/index")),
  },
  {
    path: "/chat/:appId/:id",
    element: LazyComponentWithAuth(() => import("@/pages/chat/index")),
  },
  {
    path: "/403",
    element: LazyComponentWithAuth(() => import("@/pages/403")),
  },
  {
    path: "*",
    element: LazyComponentWithAuth(() => import("@/pages/404")),
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={Routes} />;
};
